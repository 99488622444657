import { useEffect, useState, useCallback, useRef } from 'react';

const useWakeLock = (audioRef) => {
    const [wakeLock, setWakeLock] = useState(null);
    const wakeLockRef = useRef(wakeLock); // Use a ref to persist the wakeLock across re-renders

    // Update the ref whenever the state changes
    useEffect(() => {
        wakeLockRef.current = wakeLock;
    }, [wakeLock]);

    const isAudioPlaying = useCallback(() => {
        const audioElement = audioRef.current;
        return audioElement && !audioElement.paused;
    }, [audioRef]);

    async function activateWakeLock() {
        if (wakeLockRef.current === null && isAudioPlaying()) {
            try {
                const newWakeLock = await navigator.wakeLock.request('screen');
                setWakeLock(newWakeLock); // Update state only when wake lock is successfully acquired
                console.log('Wake Lock activated');
            } catch (err) {
                console.error(`Failed to activate Wake Lock: ${err.message}`);
            }
        }
    }

    async function deactivateWakeLock() {
        if (wakeLockRef.current !== null) {
            try {
                await wakeLockRef.current.release();
                setWakeLock(null); // Update state only when wake lock is successfully released
                console.log('Wake Lock deactivated');
            } catch (err) {
                console.error(`Failed to deactivate Wake Lock: ${err.message}`);
            }
        }
    }

    function handleVisibilityChange() {
        if (document.visibilityState === 'visible' && isAudioPlaying()) {
            activateWakeLock();
        } else if (document.visibilityState === 'hidden') {
            deactivateWakeLock();
        }
    }

    useEffect(() => {
        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            deactivateWakeLock();
        };
    }, [isAudioPlaying]); // Removed wakeLock from the dependency array

    return { wakeLock, activateWakeLock, deactivateWakeLock };
}

export { useWakeLock };