import { useEffect, useRef, useState } from 'react';
import Amplitude from 'amplitudejs';
import { useWakeLock } from './hooks/useWakeLock';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import './App.css';

function App() {
  const [loading, setLoading] = useState(null);
  const [openPlaylist, setOpenPlaylist] = useState(true);
  const showPlaylistButton = useRef(null);

  const audioRef = useRef(null);

  const { wakeLock, activateWakeLock, deactivateWakeLock } = useWakeLock(audioRef);

  const getSongName = () => {
    const song = document.getElementById("song-playing").textContent;
    let idString = song.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    idString = idString.replace(/\s+/g, '-');

    return idString;
  }

  useEffect(() => {
    Amplitude.init({
      "bindings": {
        32: 'play_pause'
      },
      "songs": [
        {
          "name": "O Dia Chegou",
          "artist": "Endine",
          "album": "EP",
          "url": "https://endine.com.br/ep/endine-o_dia_chegou.mp3",
          "cover_art_url": "https://endine.com.br/ep/endine_cover.jpg"
        },
        {
          "name": "Duas Semanas",
          "artist": "Endine",
          "album": "EP",
          "url": "https://endine.com.br/ep/endine-duas_semanas.mp3",
          "cover_art_url": "https://endine.com.br/ep/endine_cover.jpg"
        },
        {
          "name": "Antídoto",
          "artist": "Endine",
          "album": "EP",
          "url": "https://endine.com.br/ep/endine-antidoto.mp3",
          "cover_art_url": "https://endine.com.br/ep/endine_cover.jpg"
        },
        {
          "name": "Ismália",
          "artist": "Endine",
          "album": "EP",
          "url": "https://endine.com.br/ep/endine-ismalia.mp3",
          "cover_art_url": "https://endine.com.br/ep/endine_cover.jpg"
        }
      ],
      callbacks: {
        play: function() {
          activateWakeLock();
          const songName = getSongName();
          window.dataLayer?.push({'event':'song_plays','songName':songName});
        },
        pause: function() {
          deactivateWakeLock();
        },
        ended: function() {
          deactivateWakeLock(); 
          const songName = getSongName();
          window.dataLayer?.push({'event':'song_ends','songName':songName});
        },
        loadstart: function() {
          setLoading(true);
        },
        canplay: function() {
          setLoading(false);
        }
      }
    });

    audioRef.current = Amplitude.getAudio();

  }, [Amplitude, document])

  const openClasses = ['display-block'].join(' ').trim();
  const closedClasses = ['display-none'].join(' ').trim();

  return (
    <div className="example-container">
      <div id="white-player">
        <div className="white-player-top">
          <div>
            &nbsp;
          </div>

          <div onClick={() => setOpenPlaylist(true)}>
            <img src="https://521dimensions.com/img/open-source/amplitudejs/examples/dynamic-songs/show-playlist.svg" className="show-playlist" ref={showPlaylistButton}/>
          </div>
        </div>

        <div id="white-player-center">
          <img data-amplitude-song-info="cover_art_url" className="main-album-art" />
          <div className="song-meta-data">
            <span id="song-playing" data-amplitude-song-info="name" className="song-name"></span>
            <span data-amplitude-song-info="artist" className="song-artist"></span>
          </div>

          <div className="time-progress">
            <div id="progress-container">
              <input type="range" className="amplitude-song-slider" />
              <progress id="song-played-progress" className="amplitude-song-played-progress"></progress>
              <progress id="song-buffered-progress" className="amplitude-buffered-progress" value="0"></progress>
            </div>

            <div className="time-container">
              <span className="current-time">
                <span className="amplitude-current-minutes"></span>:<span className="amplitude-current-seconds"></span>
              </span>
              <span className="duration">
                <span className="amplitude-duration-minutes"></span>:<span className="amplitude-duration-seconds"></span>
              </span>
            </div>
          </div>
        </div>

        <div id="white-player-controls">
          <div className="amplitude-shuffle amplitude-shuffle-off" id="shuffle"></div>
          <div className="amplitude-prev" id="previous"></div>
          <div className="amplitude-play-pause" id="play-pause">
            {loading && <Spin indicator={
              <LoadingOutlined
                style={{
                  fontSize: 79.5,
                  color: 'white',
                  margin: 3
                }}
                spin
              />
            }
            ></Spin>
            }
          </div>
          <div className="amplitude-next" id="next"></div>
          <div className="amplitude-repeat" id="repeat"></div>
        </div>

        <div id="white-player-playlist-container" className={openPlaylist ? openClasses : closedClasses}>
          <div className="white-player-playlist-top">
            <div>

            </div>
            <div>
              <span className="queue">Endine - EP</span>
            </div>
            <div onClick={() => setOpenPlaylist(false)}>
              <img src="https://521dimensions.com/img/open-source/amplitudejs/examples/dynamic-songs/close.svg" className="close-playlist" />
            </div>
          </div>

          <div className="white-player-playlist">
            <div className="white-player-playlist-song amplitude-song-container amplitude-play-pause" data-amplitude-song-index="0">
              <img src="https://endine.com.br/ep/endine_cover.jpg" />

              <div className="playlist-song-meta">
                <span className="playlist-song-name">O Dia Chegou</span>
                <span className="playlist-artist-album">Endine</span>
              </div>
            </div>
            <div className="white-player-playlist-song amplitude-song-container amplitude-play-pause" data-amplitude-song-index="1">
              <img src="https://endine.com.br/ep/endine_cover.jpg" />

              <div className="playlist-song-meta">
                <span className="playlist-song-name">Duas Semanas</span>
                <span className="playlist-artist-album">Endine</span>
              </div>
            </div>
            <div className="white-player-playlist-song amplitude-song-container amplitude-play-pause" data-amplitude-song-index="2">
              <img src="https://endine.com.br/ep/endine_cover.jpg" />

              <div className="playlist-song-meta">
                <span className="playlist-song-name">Antídoto</span>
                <span className="playlist-artist-album">Endine</span>
              </div>
            </div>
            <div className="white-player-playlist-song amplitude-song-container amplitude-play-pause" data-amplitude-song-index="3">
              <img src="https://endine.com.br/ep/endine_cover.jpg" />

              <div className="playlist-song-meta">
                <span className="playlist-song-name">Ismália</span>
                <span className="playlist-artist-album">Endine</span>
              </div>
            </div>
          </div>

          <div className="white-player-playlist-controls">
            <img data-amplitude-song-info="cover_art_url" className="playlist-album-art" />

            <div className="playlist-controls">
              <div className="playlist-meta-data">
                <span data-amplitude-song-info="name" className="song-name"></span>
                <span data-amplitude-song-info="artist" className="song-artist"></span>
              </div>

              <div className="playlist-control-wrapper">
                <div className="amplitude-prev" id="playlist-previous"></div>
                <div className="amplitude-play-pause" id="playlist-play-pause">
                  {loading && <Spin indicator={
                    <LoadingOutlined
                      style={{
                        fontSize: 32,
                        color: 'white',
                      }}
                      spin
                    />
                  }
                  ></Spin>
                  }
                </div>
                
                <div className="amplitude-next" id="playlist-next"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
